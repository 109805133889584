import React from "react";
import "swiper/components/navigation/navigation.scss";

import { Description, AboutContainer, Mail,About, Exibition, Pubs } from "./AboutElements";

const AboutSection = ({
  elements
}) => {
  const handleClick = event => {
    event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
  }
  return (
    <AboutContainer
      elements={elements}
      
    >
      <About>
        <Description>
          I'm a Designer specialized in Branding, Visual Identity and Product Design.
        </Description>
        <Description>
          After several years working with product development, I start to work with graphic design,
          most international companies, creating strategies to build timeless brands.

        </Description>
        <p></p>
        {/* <Phone onClick={ handleClick } className="link-about" href="tel:351-913-700-157">
          T:+351 913 700 157
        </Phone> */}
        <Mail onClick={ handleClick }
          className="link-about" 
          href="mailto:miguel@miguellopes.info">
            miguel@miguellopes.info
        </Mail>
        </About>
        <Exibition>
          <Description>Exhibitions:</Description>
          <p></p>
          <Description>EXD Experimenta Design 13'</Description>
          <Description>Paris Design Week 2014</Description>
          <Description>MUJI Awards 2014</Description>
        </Exibition>
        <Pubs>
          <Description>Publications:</Description>
          <p></p>
          <Description>Minimalissimo</Description>
          <Description>Leibal</Description>
          <Description>ICON Design</Description>
          <Description>Domus</Description>
          <Description>Gessato</Description>
          <Description>Lovark</Description>
          {/* <Description>gooood</Description> */}
        </Pubs>
        
    </AboutContainer>
  );
};

export default AboutSection;
